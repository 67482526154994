import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Modules from "../components/modules"
import SanityImage from "../components/SanityImage"
// import { _linkResolver } from "../core/utils"
// import ProductCard from "../components/ProductCard"
import ProductsUI from "../components/modules/ProductsUI"
import AddToCart from "../components/shop/AddToCart"
// import SliderUI from "../components/modules/SliderUI"
import { publish, subscribe, unsubscribe } from "pubsub-js"
// import useDeviceDetect from "../hooks/useDeviceDetect"

import AccordionImages from "../components/AccordionImages"
import SlickSlider from "../components/ui/slick-slider"
import { _linkResolver } from "../core/utils"
import clsx from "clsx"

export const query = graphql`
  query ProductBySlug($slug: String!, $categorySlug: String!) {
    sanityProduct(slug: { current: { eq: $slug } }) {
      id
      seo {
        ...seo
      }
      slug {
        current
      }
      _type
      addToCartLabel
      title
      shortDescription
      price
      weight
      isbn
      bbIndex
      imagesCover: _rawImagesCover(resolveReferences: { maxDepth: 10 })
      imagesDetail: _rawImagesDetail(resolveReferences: { maxDepth: 10 })
      imagesDetailCrop
      modules {
        ... on SanityTexteUI {
          _key
          _type
          title
          aside {
            texte: _rawTexte(resolveReferences: { maxDepth: 10 })
          }
          texte: _rawTexte(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityContributeursUI {
          _key
          _type
          title
          contributeur {
            _type
            title
            role
            texte: _rawTexte(resolveReferences: { maxDepth: 10 })
            products {
              ...productCard
            }
          }
        }
      }
      upsells {
        ...productCard
      }
    }
    related: allSanityProduct(
      filter: {
        category: { slug: { current: { eq: $categorySlug } } }
        slug: { current: { ne: $slug } }
        comingSoon: { active: { ne: true } }
      }
    ) {
      nodes {
        ...productCard
      }
    }
  }
`

const PageProduct = ({ location, data }) => {
  // console.log(location)
  const {
    seo,
    slug,
    addToCartLabel,
    title,
    shortDescription,
    // isbn,
    price,
    weight,
    bbIndex,
    imagesCover,
    imagesDetail,
    imagesDetailCrop,
    modules,
    upsells,
  } = data.sanityProduct

  const { related } = data
  const all = upsells.concat(related.nodes)
  const uspellsAndRelated = all.slice(0, 4)

  // console.table(upsells)
  // console.table(related.nodes)
  // console.log(all)
  // console.log(uspellsAndRelated)

  const [expand, setExpand] = useState(false)
  const [index, setIndex] = useState(1)
  // const { isMobile } = useDeviceDetect()
  // const sectionImages = useRef()
  //concat image cover with images detail, set new array same as slider module
  // console.log({ imagesCover })
  // console.log({ imagesDetail })
  const coverImage = imagesCover[0]
  coverImage.size = "contain"
  const images = [coverImage, ...(imagesDetail || [])].map(el => {
    return { image: el }
  })

  console.log({ images })
  // const images = [coverImage, ...imagesDetail].map(el => {
  //   return { image: el }
  // })
  const length = images.length || 0

  useEffect(() => {
    const token = subscribe("SLIDER_AFTER_CHANGE", (e, d) => {
      setIndex(d + 1)
    })
    return () => unsubscribe(token)
  }, [])

  useEffect(() => {
    if (expand) {
      const mainHeader = document.querySelector(".main-header")
      mainHeader.scrollIntoView({
        behavior: "smooth",
        // block: "end",
        // inline: "nearest",
      })
    }
  }, [expand])

  // const _getDetailExcerptOrFull = () => {
  //   if (expand) {
  //     return imagesDetail
  //   } else {
  //     return imagesDetail.slice(0, 2)
  //   }
  // }

  const _getDetailExcerptAndFull = () => {
    const excerpt = imagesDetail.slice(0, 2)
    const detail = imagesDetail.slice(2, imagesDetail.length)
    return { excerpt, detail }
  }

  return (
    <div className="main-area bg-secondary">
      {seo && (
        <Seo
          metaTitle={seo.metaTitle}
          metaDescription={seo.metaDescription}
          metaImage={seo.metaImage?.asset.url}
          template={`page-product page-${slug.current}`}
          page={true}
        />
      )}

      <section className="hero-slider bb relative">
        {/* <SliderUI input={{ images, showCaption: false }} /> */}
        <SlickSlider>
          {images.map(({ image }, i) => (
            <div className="slide h-screen w-screen" key={i}>
              <div
                className={clsx(
                  "cover- h-full"
                  // imagesDetailCrop ? "cover" : ""
                )}
                style={{
                  backgroundImage: `url(${image?.asset.url})`,
                  backgroundSize: image?.size
                    ? image?.size
                    : imagesDetailCrop
                    ? "cover"
                    : "contain",
                  // backgroundSize: imagesDetailCrop ? "cover" : "cover)----",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "black",
                }}
              ></div>
            </div>
          ))}
        </SlickSlider>
        <div className="hero-header absolute top-0 text-secondary px-sm md:px-md py-sm flex">
          <h1 className="italic pr-smAlt">{title}</h1>
          <div className="pl-sm-">{price} €</div>
        </div>
        <div className="add-to-cart_wrapper absolute h-full right-0 top-0 z-30 text-secondary- mix-blend-difference- pointer-events-none">
          <div className="sticky top-header-height z-10 px-sm md:px-md py-sm pointer-events-auto ">
            <AddToCart
              id={slug.current}
              price={price}
              weight={weight}
              url={_linkResolver(data.sanityProduct)}
              description={shortDescription}
              image={coverImage.asset.url}
              name={title}
              label={addToCartLabel || "Acheter"}
            />
          </div>
        </div>
      </section>

      <section className="main-header flex px-sm md:px-md py-9 pb-8 bb">
        <div className="w-1/2 md:w-1/3">
          <div className="">{bbIndex}</div>
        </div>
        <div className="w-1/3 text-center hidden-sm">
          <button
            className="text-tertiary"
            onClick={() => setExpand(!expand)}
            onKeyDown={() => setExpand(!expand)}
          >
            <div className="pointer-events-none ">
              {expand ? <span>Fermer</span> : <span>Galerie d’images</span>}
            </div>
          </button>
        </div>
        <div className="w-1/2 md:w-1/3">
          <ul className="flex justify-end">
            <li className="pl-sm hidden-sm">
              <span className="slide-index">{index}</span>/
              <span className="slider-length">{length}</span>
            </li>
          </ul>
        </div>
      </section>

      <div className="hidden-sm">
        <AccordionImages expand={expand}>
          <section className="images grid grid-cols-4 gap-1 bb">
            {imagesDetail &&
              imagesDetail.length &&
              imagesDetail.length > 0 &&
              imagesDetail.map((image, i) => (
                <div
                  className="grid-item cursor-pointer-"
                  key={i}
                  role="button"
                  tabIndex={-1}
                  onClick={() => publish("SLIDE_INDEX", i + 1)}
                  onKeyDown={() => publish("SLIDE_INDEX", i + 1)}
                >
                  <SanityImage image={image} />
                </div>
              ))}
            <div className="bb py-8 px-sm sm-only text-center text-tertiary">
              Voir plus d’images
            </div>
          </section>
        </AccordionImages>
      </div>
      <div className="sm-only">
        <section className="images grid grid-cols-1 gap-1 bb- mb-1">
          {imagesDetail &&
            imagesDetail.length &&
            imagesDetail.length > 0 &&
            _getDetailExcerptAndFull().excerpt.map((image, i) => (
              <div
                className="grid-item cursor-pointer-"
                key={i}
                role="button"
                tabIndex={-1}
                onClick={() => publish("SLIDE_INDEX", i + 1)}
                onKeyDown={() => publish("SLIDE_INDEX", i + 1)}
              >
                <SanityImage image={image} />
              </div>
            ))}
        </section>
        <AccordionImages expand={expand}>
          <section className="images grid grid-cols-1 gap-1 bb-">
            {imagesDetail &&
              imagesDetail.length &&
              imagesDetail.length > 0 &&
              _getDetailExcerptAndFull().detail.map((image, i) => (
                <div
                  className="grid-item cursor-pointer-"
                  key={i}
                  role="button"
                  tabIndex={-1}
                  onClick={() => publish("SLIDE_INDEX", i + 1)}
                  onKeyDown={() => publish("SLIDE_INDEX", i + 1)}
                >
                  <SanityImage image={image} />
                </div>
              ))}
          </section>
        </AccordionImages>
        <button
          className="bb py-8 px-sm sm-only text-center text-tertiary w-full"
          tabIndex={-1}
          onClick={() => setExpand(!expand)}
          onKeyDown={() => setExpand(!expand)}
        >
          {expand ? (
            <span>Voir moins d’images</span>
          ) : (
            <span>Voir plus d’images</span>
          )}
        </button>
      </div>

      {modules && <Modules input={modules} />}

      {uspellsAndRelated && (
        <section className="related bb- bt-">
          <h2 className="py-8 px-sm md:px-md bb">Voir aussi</h2>
          <ProductsUI
            input={{ showFilters: false, products: uspellsAndRelated }}
          />
        </section>
      )}
    </div>
  )
}

export default PageProduct
