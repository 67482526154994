import React, { useEffect } from "react"
import { motion, useAnimation } from "framer-motion"
// import { publish } from "pubsub-js"
// import SanityImage from "./SanityImage"

const AccordionImages = ({ children, expand }) => {
  const controls = useAnimation()
  const variants = {
    expanded: { opacity: 1, height: "auto" },
    collapsed: { opacity: 0, height: 0 },
  }

  useEffect(() => {
    if (expand) {
      controls.start("expanded")
    } else {
      controls.start("collapsed")
    }
  }, [expand, controls])

  return (
    <motion.div
      initial="collapsed"
      className="z-0 overflow-hidden"
      animate={controls}
      transition={{ duration: 0.3 }}
      variants={variants}
    >
      {children}
    </motion.div>
  )
}

export default AccordionImages
