import React, { useEffect, useRef } from "react"
import clsx from "clsx"
import ReactSlick from "react-slick"
import { subscribe, unsubscribe, publish } from "pubsub-js"
import useDeviceDetect from "../../../hooks/useDeviceDetect"
import styled from "styled-components"
import "./slick.css" //impossible de l'importer direct ici à cause de purge-css

const Section = styled.section`
  position: relative;
  div {
    vertical-align: top;
  }
  &.has-black-arrows {
    button.slick-arrow.slick-prev {
      cursor: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='31.61' height='21.21' viewBox='0 0 31.61 21.21'%3e%3ctitle%3eFichier 2%3c/title%3e%3cpolygon fill='black' points='31.61 9.61 3.83 9.61 12.02 1.42 10.61 0 1.42 9.19 0 10.61 1.42 12.02 10.61 21.21 12.02 19.8 3.83 11.61 31.61 11.61 31.61 9.61'/%3e%3c/svg%3e")
          15 11,
        w-resize;
    }
    button.slick-arrow.slick-next {
      cursor: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31.61 21.21' width='31.61' height='21.21'%3e%3ctitle%3eFichier 1%3c/title%3e%3cpolygon fill='black' points='0 11.61 27.78 11.61 19.59 19.8 21 21.21 30.19 12.02 31.61 10.61 30.19 9.19 21 0 19.59 1.42 27.78 9.61 0 9.61 0 11.61'/%3e%3c/svg%3e")
          15 11,
        e-resize;
    }
  }
  button.slick-arrow {
    @media all and (max-width: 768px) {
      display: none;
    }
    position: absolute;
    top: 0;
    width: 30%;
    height: 100%;
    text-indent: -9999px;
    background: transparent;
    z-index: 20;
    display: block !important;
    cursor: none;
    &.slick-prev {
      left: 0;
      /* cursor: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='31.61' height='21.21' viewBox='0 0 31.61 21.21'%3e%3ctitle%3eFichier 2%3c/title%3e%3cpolygon fill='white' points='31.61 9.61 3.83 9.61 12.02 1.42 10.61 0 1.42 9.19 0 10.61 1.42 12.02 10.61 21.21 12.02 19.8 3.83 11.61 31.61 11.61 31.61 9.61'/%3e%3c/svg%3e")
          15 11,
        w-resize; */
    }

    &.slick-next {
      right: 0;
      /* cursor: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31.61 21.21' width='31.61' height='21.21'%3e%3ctitle%3eFichier 1%3c/title%3e%3cpolygon fill='white' points='0 11.61 27.78 11.61 19.59 19.8 21 21.21 30.19 12.02 31.61 10.61 30.19 9.19 21 0 19.59 1.42 27.78 9.61 0 9.61 0 11.61'/%3e%3c/svg%3e")
          15 11,
        e-resize; */
    }
  }
  .gatsby-image-wrapper [data-main-image] {
    max-height: 100vh;
    // margin: auto;
  }
  img {
    max-height: var(--main-height);
  }
  .pager {
    line-height: 1;
    @media all and (max-width: 768px) {
      color: black;
    }
  }
`

const SlickSlider = ({ children, settingsOverride }) => {
  // const [current, setCurrent] = useState(0)
  // const [length, setLength] = useState(input.length || 0);
  const { isMobile } = useDeviceDetect()
  const sliderRef = useRef()

  // console.log(isHome)

  useEffect(() => {
    document.addEventListener("keydown", _onKey)
    const token = subscribe("SLIDE_INDEX", (e, d) => {
      // console.log(d)
      sliderRef.current.slickGoTo(d)
    })

    return () => {
      document.removeEventListener("keydown", _onKey)
      unsubscribe(token)
    }
  }, [])

  const _onKey = e => {
    // console.log(e.keyCode);
    switch (e.keyCode) {
      case 37:
        sliderRef.current.slickPrev()
        break
      case 39:
        sliderRef.current.slickNext()
        break
      default:
        break
    }
  }
  // const _onEdge = () => {};
  // const _sliderBeforeChange = (oldIndex, newIndex) => {};
  const _sliderAfterChange = index => {
    // setCurrent(index)
    publish("SLIDER_AFTER_CHANGE", index)
    // titleRef.current.innerText = input.images[index].caption
    // currentRef.current.innerText = pad2(index + 1)
  }

  // useEffect(() => {

  // }, [current])

  const settings = {
    // fade: !isMobile,
    dots: false,
    speed: isMobile ? 300 : 800,
    cssEase: isMobile ? "ease" : "cubic-bezier(0.8, 0, 0, 1)",
    // cssEase: "cubic-bezier(0.8, 0, 0, 1)",
    swipe: true,
    // lazyLoad: true,
    // beforeChange: _sliderBeforeChange,
    afterChange: _sliderAfterChange,
  }

  return (
    <Section className={clsx("slider-ui bb ")}>
      <ReactSlick {...settings} ref={sliderRef}>
        {children}
      </ReactSlick>
    </Section>
  )
}

export default SlickSlider
